/* eslint-disable no-unused-vars */
import Calendar from "views/Calendar/Calendar.jsx";
import Dashboard from "views/Dashboard/Dashboard.jsx";

//updated items
import Wizard from "./views/Forms/MeetingWizard/Wizard";
import MeetingForm from "./views/Forms/MeetingForm";
import MemberProfile from "./views/Forms/MemberProfile";
import MeetingDashboard from "views/Pages/MeetingDashboard.jsx";
import MembersDashboard from "./views/Pages/MembersDashboard";
import ReportsDashboard from "./views/Pages/ReportsDashboard";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from "@material-ui/icons/DateRange";
import AccountBalance from "@material-ui/icons/AccountBalance";
import People from "@material-ui/icons/People";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Meetings",
    icon: AccountBalance,
    state: "pageCollapse",
    views: [
      {
        path: "/meetings",
        name: "Manage",
        component: MeetingDashboard,
        layout: "/admin"
      },
      {
        path: "/logging",
        name: "Votes",
        component: ReportsDashboard,
        layout: "/admin"
      },
      {
        path: "/wizard",
        name: "Meeting Wizard",
        component: Wizard,
        layout: "/admin",
        invisible: true
      }
    ]
  },
  {
    path: "/members",
    name: "Members",
    icon: People,
    component: MembersDashboard,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "Create Member",
    icon: People,
    component: MemberProfile,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: DateRange,
    component: Calendar,
    layout: "/admin"
  }
];

export const formRoutes = [
  {
    path: "/newmeeting",
    name: "New Meeting",
    icon: DateRange,
    component: MeetingForm,
    layout: "/admin"
  }
];

export default dashRoutes;
