/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const MeetingForm = ({ ...props }) => {
  return (
    <div>
      <Dialog
        open={props.visible}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">New Meeting Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the name for the new meeting type you would like to add
            to the platform.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Meeting Name"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="error">
            Cancel
          </Button>
          <Button onClick={props.handleClose} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MeetingForm;
