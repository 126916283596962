/* eslint-disable no-unused-vars */
import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table-v6";
import matchSorter from "match-sorter";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import People from "@material-ui/icons/People";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  ...buttonStyle
};

class MembersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      show: false
    };
  }

  render() {
    const { classes } = this.props;
    const columns = [
      {
        Header: "First Name",
        id: "firstName",
        accessor: d => d.firstName,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["firstName"] }),
        filterAll: true
      },
      {
        Header: "Last Name",
        id: "lastName",
        accessor: d => d.lastName,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["lastName"] }),
        filterAll: true
      },
      {
        Header: "Term Start",
        id: "termStart",
        accessor: d => d.termStart.toDate().toDateString(),
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["termStart"] }),
        filterAll: true
      },
      {
        Header: "Term End",
        id: "termEnd",
        accessor: d => d.termEnd.toDate().toDateString(),
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["termEnd"] }),
        filterAll: true
      },
      {
        Header: "Member Of",
        id: "belongsTo",
        accessor: data => {
          let output = [];
          data.belongsTo.map(meeting => {
            output.push(`${meeting.label}`);
          });
          return output.join(", ");
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["belongsTo"] }),
        filterAll: true
      },
      {
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        filterable: false,
        resizable: true,
        Cell: props => (
          <div className="actions-right">
            {/* <Button
              justIcon
              round
              simple
              // onClick={this.props.showInfo}
              color="info"
              className="edit"
            >
              <Assignment />
            </Button>{" "} */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                this.props.showInfo(props.original);
              }}
              color="warning"
              className="edit"
            >
              <Edit />
            </Button>
          </div>
        )
      }
    ];
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <People />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>DigiVote Members</h4>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.props.members}
                  columns={columns}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(MembersTable);
