/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import MeetingTable from "../Tables/MeetingTable";
import AgendaInfo from "../ModalConfigs/AgendaDetail";

class ReportsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: true,
      data: null
    };
  }

  handleChange = () => {
    this.setState(prevState => ({
      ...prevState,
      table: !prevState.table
    }));
  };

  handleAgenda = data => {
    this.setState(prevState => ({
      agenda: data,
      table: !prevState.table
    }));
  };

  render() {
    return (
      <React.Fragment>
        {this.state.table ? (
          <MeetingTable showInfo={this.handleAgenda} votes={this.props.votes} />
        ) : (
          <AgendaInfo
            showTable={this.handleChange}
            agenda={this.state.agenda}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    votes: state.firestore.ordered.votes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onIncrementDummy: () => dispatch(increment()),
    // onStoreResult: val => dispatch(addResult(val)),
    // onDeleteResult: resultId => dispatch(deleteResult(resultId))
  };
};

export default compose(
  firestoreConnect([{ collection: "votes" }]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ReportsDashboard);
