/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class PhotoCrop extends PureComponent {
  state = {
    crop: {
      unit: "%",
      width: 30,
      aspect: 3 / 4
    },
    open: true
  };

  //   onSelectFile = e => {
  //     if (e.target.files && e.target.files.length > 0) {
  //       const reader = new FileReader();
  //       reader.addEventListener("load", () =>
  //         this.setState({ src: reader.result })
  //       );
  //       reader.readAsDataURL(e.target.files[0]);
  //     }
  //   };

  hideDialog = () => {
    this.props.uploadImage(this.state.croppedImageUrl);
    this.setState({ open: false });
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
    this.setState({
      open: true
    });
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        `cropped-${this.props.name}`
      );
      this.setState({ croppedImageUrl });
      console.log(croppedImageUrl);
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        blob.lastModifiedDate = new Date();
        resolve(blob);
        //     window.URL.revokeObjectURL(this.fileUrl);
        //     this.fileUrl = window.URL.createObjectURL(blob);
        //     resolve(this.fileUrl);
        //   }, "image/jpeg");
      });
    });
  }

  render() {
    const { crop, croppedImageUrl } = this.state;
    const { src } = this.props;

    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.hideDialog}
          TransitionComponent={Transition}
        >
          <AppBar style={{ position: "relative" }} color="primary">
            <Toolbar>
              <IconButton
                edge="start"
                color="secondary"
                onClick={this.hideDialog}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">
                Please Crop Image for Displaying on Room Panels
              </Typography>
            </Toolbar>
          </AppBar>
          {src && (
            <div
              style={{
                padding: "20px"
              }}
            >
              <ReactCrop
                src={src}
                crop={crop}
                style={{ maxWidth: "35%" }}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            </div>
          )}
          {/* {croppedImageUrl && (
            <div
              style={{
                padding: "20px"
              }}
            >
              <img
                alt="Crop"
                style={{ maxWidth: "50%" }}
                src={croppedImageUrl}
              />
            </div>
          )} */}
        </Dialog>
      </div>
    );
  }
}

export default PhotoCrop;
