/* eslint-disable no-unused-vars */
import React, { useState } from "react";

//Material UI Core Components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";

// Material UI Icons
import Check from "@material-ui/icons/Check";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import { withStyles } from "@material-ui/core";

const MeetingSettings = props => {
  const [checked, setChecked] = useState(false);
  const [simpleSelect, setSimpleSelect] = useState("");

  const handleChange = () => {
    if (checked === true) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  const handleSimple = event => {
    //this.setState({ [event.target.name]: event.target.value });
    setSimpleSelect(event.target.value);
  };

  return (
    <div className={props.classes.block}>
      <FormControlLabel
        control={
          <Checkbox
            tabIndex={-1}
            onClick={handleChange}
            checkedIcon={<Check className={props.classes.checkedIcon} />}
            icon={<Check className={props.classes.uncheckedIcon} />}
            classes={{
              checked: props.classes.checked,
              root: props.classes.checkRoot
            }}
          />
        }
        classes={{
          label: props.classes.label
        }}
        label="Uses Voting"
      />
      <FormControlLabel
        control={
          <Checkbox
            tabIndex={-1}
            onClick={handleChange}
            checkedIcon={<Check className={props.classes.checkedIcon} />}
            icon={<Check className={props.classes.uncheckedIcon} />}
            classes={{
              checked: props.classes.checked,
              root: props.classes.checkRoot
            }}
          />
        }
        classes={{
          label: props.classes.label
        }}
        label="Live Voting"
      />
      <FormControlLabel
        control={
          <Checkbox
            tabIndex={-1}
            onClick={handleChange}
            checkedIcon={<Check className={props.classes.checkedIcon} />}
            icon={<Check className={props.classes.uncheckedIcon} />}
            classes={{
              checked: props.classes.checked,
              root: props.classes.checkRoot
            }}
          />
        }
        classes={{
          label: props.classes.label
        }}
        label="Request to Speak List"
      />
      <FormControl fullWidth className={props.classes.selectFormControl}>
        <InputLabel
          htmlFor="simple-select"
          className={props.classes.selectLabel}
        >
          Meeting Manager Position
        </InputLabel>
        <Select
          MenuProps={{
            className: props.classes.selectMenu
          }}
          classes={{
            select: props.classes.select
          }}
          value={simpleSelect}
          onChange={handleSimple}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select"
          }}
        >
          <MenuItem
            disabled
            classes={{
              root: props.classes.selectMenuItem
            }}
          >
            Position
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="2"
          >
            Postion 1
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="3"
          >
            Position 2
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="4"
          >
            Postion 3
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="5"
          >
            Position 4
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="6"
          >
            Position 5
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="7"
          >
            Position 6
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="8"
          >
            Position 7
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="9"
          >
            Position 8
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="10"
          >
            Position 9
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="11"
          >
            Position 10
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default withStyles(extendedFormsStyle)(MeetingSettings);
