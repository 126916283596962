/* eslint-disable no-unused-vars */
import React from "react";

// @material-ui/icons
import CardTravel from "@material-ui/icons/CardTravel";
import Flag from "@material-ui/icons/Flag";
import HowToVote from "@material-ui/icons/HowToVote";
import Extension from "@material-ui/icons/Extension";
import Fingerprint from "@material-ui/icons/Fingerprint";
import FlightLand from "@material-ui/icons/FlightLand";
import Build from "@material-ui/icons/Build";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

// ##############################
// // // stories for RTLSupport view
// #############################

const rtlStories = [
  {
    // First story
    inverted: true,
    badgeColor: "danger",
    badgeIcon: CardTravel,
    title: "جهة أي",
    titleColor: "danger",
    body: (
      <p>
        قام كل ماذا العصبة اوروبا. أي جورج العالمي أخر, كان تم أطراف القوى
        استبدال. أسر ميناء تكتيكاً الجديدة، كل. جُل اللا التكاليف بـ, عرفها
        النزاع لليابان بـ أضف. انتهت المدن الثالث من وقد.وقبل قادة إحتار عن أخر.
        حين ونتج أخرى قد. بالعمل بالمطالبة فقد قد. عن جنوب ومضى الشتاء.
      </p>
    ),
    footerTitle: "مدن أن هُزم سكان, مكن."
  },
  {
    // Second story
    inverted: true,
    badgeColor: "success",
    badgeIcon: Extension,
    title: "جُل حكومة",
    titleColor: "success",
    body: (
      <p>
        عل فكانت الثقيلة بلا. شيء بخطوط بالرّغم التبرعات عن, يطول بأيدي لم كلّ.
        معقل الغالي واتّجه لم وتم, أن الصفحة بالمحور حول, بال مرمى الصفحات
        قُدُماً و. الأخذ سبتمبر العالم من ذلك. ان يبق شدّت الأبرياء, الى الربيع،
        والمانيا كل. ودول الأهداف التقليدي عل أضف, كلا يقوم الأخذ الآلاف بل.
      </p>
    )
  },
  {
    // Third story
    inverted: true,
    badgeColor: "info",
    badgeIcon: Fingerprint,
    title: "هذا غينيا",
    titleColor: "info",
    body: (
      <p>
        جهة المارق والديون التقليدية في, هو وترك المجتمع بريطانيا ذلك, لمّ ما
        العالم، اليابان،. ٣٠ فقامت أوروبا مشاركة بعد, ٢٠٠٤ الجو مساعدة ما حدى.
        في عليها وبحلول معارضة بعض. عن الأرض وبداية العمليات ولم. الجو جديداً
        الأوروبيّون أم به،. ثم التي نتيجة الآلاف جعل, عن المارق السادس قام. ما
        أخر فقامت الأجل الشرق،, فصل كل وسوء الأرواح. ثم بعد وشعار بأيدي. قبل
        وكسبت الغالي الولايات بل, ٣٠ أمّا أخرى لأداء أضف. هو منتصف معزّزة على.
        بـ أفريقيا التغييرات مما, أثره،.
      </p>
    ),
    footer: (
      <CustomDropdown
        rtlActive
        buttonIcon={Build}
        buttonProps={{
          round: true,
          style: { marginBottom: "0" },
          color: "info"
        }}
        dropdownList={[
          "ان",
          "إجلاء لفرنسا",
          "أواخر الأرض بل",
          { divider: true },
          "عل اليها"
        ]}
      />
    )
  }
];

// ##############################
// // // stories for Widgets view
// #############################

const widgetStories = [
  {
    // First story
    inverted: true,
    badgeColor: "danger",
    badgeIcon: CardTravel,
    title: "Some Title",
    titleColor: "danger",
    body: (
      <p>
        Wifey made the best Father's Day meal ever. So thankful so happy so
        blessed. Thank you for making my family We just had fun with the
        “future” theme !!! It was a fun night all together ... The always rude
        Kanye Show at 2am Sold Out Famous viewing @ Figueroa and 12th in
        downtown.
      </p>
    ),
    footerTitle: "11 hours ago via Twitter"
  },
  {
    // Second story
    inverted: true,
    badgeColor: "success",
    badgeIcon: Extension,
    title: "Another One",
    titleColor: "success",
    body: (
      <p>
        Thank God for the support of my wife and real friends. I also wanted to
        point out that it’s the first album to go number 1 off of streaming!!! I
        love you Ellen and also my number one design rule of anything I do from
        shoes to music to homes is that Kim has to like it....
      </p>
    )
  },
  {
    // Third story
    inverted: true,
    badgeColor: "info",
    badgeIcon: Fingerprint,
    title: "Another Title",
    titleColor: "info",
    body: (
      <div>
        <p>
          Called I Miss the Old Kanye That’s all it was Kanye And I love you
          like Kanye loves Kanye Famous viewing @ Figueroa and 12th in downtown
          LA 11:10PM
        </p>
        <p>
          What if Kanye made a song about Kanye Royère doesn't make a Polar bear
          bed but the Polar bear couch is my favorite piece of furniture we own
          It wasn’t any Kanyes Set on his goals Kanye
        </p>
      </div>
    ),
    footer: (
      <CustomDropdown
        buttonIcon={Build}
        buttonProps={{
          round: true,
          style: { marginBottom: "0" },
          color: "info"
        }}
        dropdownList={[
          "Action",
          "Another action",
          "Something else here",
          { divider: true },
          "Separated link"
        ]}
      />
    )
  }
];

// ##############################
// // // stories for Timeline view
// #############################

const stories = [
  {
    // First story
    inverted: true,
    badgeColor: "info",
    badgeIcon: HowToVote,
    title: "Consent Agenda",
    titleColor: "info",
    body: (
      <p>
        Consider adoption of an Ordinance of the City of Denton authorizing the
        City Manager, or designee, to execute a confidentiality and
        non-disclosure agreement between the City and Saturn Power Corporation;
        providing for a severability clause; and providing for an effective
        date.
      </p>
    ),
    footerTitle: "ID 19-799",
    footer: (
      <CustomDropdown
        buttonIcon={Build}
        buttonProps={{
          round: true,
          style: { marginBottom: "0" },
          color: "success"
        }}
        dropdownList={["Add Notes", "Motioned By"]}
      />
    )
  },
  {
    // Second story
    badgeColor: "info",
    badgeIcon: HowToVote,
    title: "Consent Agenda",
    titleColor: "info",
    body: (
      <p>
        Consider adoption of an ordinance directing the publication of Notice of
        Intention to issue $9,110,000 in principal amount of Certificates of
        Obligation of the City of Denton for General Government projects; and
        providing for an effective date. Audit/Finance Committee approves 3-0
      </p>
    ),
    footerTitle: "ID 19-830",
    footer: (
      <CustomDropdown
        buttonIcon={Build}
        buttonProps={{
          round: true,
          style: { marginBottom: "0" },
          color: "success"
        }}
        dropdownList={["Add Notes", "Motioned By"]}
      />
    )
  },
  {
    // Third story
    inverted: true,
    badgeColor: "info",
    badgeIcon: HowToVote,
    title: "Consent Agenda",
    titleColor: "info",
    body: (
      <p>
        Consider adoption of an ordinance directing the publication of Notice of
        Intention to issue $17,610,000 in principal amount of Certificates of
        Obligation of the City of Denton for Wastewater System and Electric
        System projects; and providing for an effective date. Audit/Finance
        Committee approves 3-0.
      </p>
    ),
    footerTitle: "ID 19-831",
    footer: (
      <CustomDropdown
        buttonIcon={Build}
        buttonProps={{
          round: true,
          style: { marginBottom: "0" },
          color: "success"
        }}
        dropdownList={["Add Notes", "Motioned By"]}
      />
    )
  },
  {
    badgeColor: "info",
    badgeIcon: HowToVote,
    title: "Consent Agenda",
    titleColor: "info",
    body: (
      <p>
        Consider adoption of an ordinance of the City of Denton, granting the
        Denton Cinco de Mayo Festival Committee, pursuant to Section 17-20 of
        the City of Denton Code of Ordinances, a noise exception for the Denton
        Cinco de Mayo Festival, which will be held on Saturday, May 4, 2019,
        from 10:00 a.m. to 10:00 p.m. at the Quakertown Park; granting an
        increase in amplified sound levels for an outdoor music festival from 70
        to 75 dba; and providing an effective date.
      </p>
    ),
    footerTitle: "ID 19-826",
    footer: (
      <CustomDropdown
        buttonIcon={Build}
        buttonProps={{
          round: true,
          style: { marginBottom: "0" },
          color: "success"
        }}
        dropdownList={["Add Notes", "Motioned By"]}
      />
    )
  }
];

// ##############################
// // // data for populating the calendar in Calendar view
// #############################

var today = new Date();
var y = today.getFullYear();
var m = today.getMonth();
var d = today.getDate();

const events = [
  {
    title: "PZ Meeting",
    start: new Date(y, m, 4),
    end: new Date(y, m, 4),
    allDay: false,
    color: "green"
  },
  {
    title: "City Council Meeting",
    start: new Date(y, m, 11),
    end: new Date(y, m, 11),
    allDay: false,
    color: "azure"
  },
  {
    title: "PZ Meeting",
    start: new Date(y, m, 18),
    end: new Date(y, m, 18),
    allDay: false,
    color: "green"
  },
  {
    title: "City Council Meeting",
    start: new Date(y, m, 25),
    end: new Date(y, m, 25),
    allDay: false,
    color: "azure"
  }
];

// ##############################
// // // Tasks for TasksCard - see Widget view
// #############################

var bugs = [
  'Sign contract for "What are conference organizers afraid of?"',
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  "Create 4 Invisible User Experiences you Never Knew About"
];
var website = [
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];
var server = [
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];

// ##############################
// // // Tasks for TasksCard - see RTLSupport view
// #############################

var rtlBugs = [
  "فقد لمحاكم الاندونيسية, بلاده بالتوقيع تم يبق. جعل السبب وفرنسا الصينية أي.",
  "بحث. كل مما ٢٠٠٤ شاسعة العسكري جعل السبب وفرنسا الصينية أي.",
  "تسبب أفريقيا ضرب عن, عن إنطلاق جعل السبب وفرنسا الصينية أي.",
  "قدما مليون بين عن, مرجع منتصف الأمريكية جعل السبب وفرنسا الصينية أي."
];
var rtlWebsite = [
  "قدما مليون بين عن, مرجع منتصف الأمريكية جعل السبب وفرنسا الصينية أي.",
  "قدما مليون بين عن, مرجع منتصف الأمريكية جعل السبب وفرنسا الصينية أي."
];
var rtlServer = [
  "قدما مليون بين عن, مرجع منتصف الأمريكية جعل السبب وفرنسا الصينية أي.",
  "قدما مليون بين عن, مرجع منتصف الأمريكية جعل السبب وفرنسا الصينية أي.",
  "قدما مليون بين عن, مرجع منتصف الأمريكية جعل السبب وفرنسا الصينية أي."
];

// ##############################
// // // data for datatables.net in DataTables view
// #############################

const dataTable = {
  headerRow: ["Name", "Position", "Office", "Age", "Actions"],
  footerRow: ["Name", "Position", "Office", "Age", "Actions"],
  dataRows: [
    [
      "Street Beautification",
      "Pass",
      "City Council",
      "20190325",
      "https://stream.mux.com/ViaK009SJM01Zk02U35XmZGIiqfey3R58Rl.m3u8"
    ],
    [
      "Agency Agenda",
      "Pass",
      "City Council",
      "20190325",
      "https://stream.mux.com/VcJ6bVai402qMtxntB500pNFz02Sme3xrXH.m3u8"
    ],
    [
      "Pension Funds",
      "Pass",
      "City Council",
      "20190325",
      "https://stream.mux.com/kJDF01SCoKI5TknAe8kFaVfYuWXDQB0102O.m3u8"
    ],
    [
      "Police Funds",
      "Fail",
      "City Council",
      "20190216",
      "https://stream.mux.com/w5xEzkHMrltPxrHEubivk8WOgV2P1IqK.m3u8"
    ],
    [
      "Year Budget",
      "Pass",
      "City Council",
      "20181201",
      "https://stream.mux.com/HzGt01KBV004Rv002ynVy01x4js4E1JAbyA00.m3u8"
    ],
    [
      "New Shopping Center",
      "Pass",
      "City Council",
      "20190528",
      "https://stream.mux.com/w5xEzkHMrltPxrHEubivk8WOgV2P1IqK.m3u8"
    ],
    [
      "Villas at Chaparral",
      "Pass",
      "Planning and Zoning",
      "20190528",
      "https://stream.mux.com/w5xEzkHMrltPxrHEubivk8WOgV2P1IqK.m3u8"
    ],
    [
      "New Restaurant",
      "Pass",
      "Planning and Zoning",
      "20190116",
      "https://stream.mux.com/RKlJgfm01sS01I3s5wbeqvtIxfPRopx9wE.m3u8"
    ]
  ]
};

const memberTable = {
  headerRow: [
    "First Name",
    "Last Name",
    "Meetings",
    "Term Start",
    "Term End",
    "Actions"
  ],
  footerRow: [
    "First Name",
    "Last Name",
    "Meetings",
    "Term Start",
    "Term End",
    "Actions"
  ],
  dataRows: [
    ["Vernon", "Mccoy", "City Council", "20190325", "20200325"],
    ["Brian", "Nelson", "City Council", "20190325", "20200325"],
    ["Harper", "Steward", "City Council", "20190325", "20200325"],
    ["Delores", "Grant", "City Council", "20190325", "20200325"],
    ["Ronald", "Oliver", "City Council", "20190325", "20200325"],
    ["Sofia", "Nichols", "City Council", "20190325", "20200325"],
    ["Mario", "Hudson", "City Council", "20190325", "20200325"],
    ["Janet", "Carr", "City Council", "20190325", "20200325"],
    ["Chad", "Morgan", "City Council", "20190325", "20200325"],
    ["Tom", "Austin", "P&Z", "20190325", "20210325"],
    ["Enrique", "Hernandez", "P&Z", "20190325", "20210325"],
    ["Lewis", "Boyd", "P&Z", "20190325", "20210325"],
    ["Jack", "Bishop", "P&Z", "20190325", "20210325"]
  ]
};

export {
  // data for React Big Calendar in Calendar view
  events,
  // stories for RTLSupport view
  rtlStories,
  // stories for Widgets view
  widgetStories,
  // stories for Timeline view
  stories,
  // these 3 are used to create the tasks lists in TasksCard - Widget view
  bugs,
  website,
  server,
  // these 3 are used to create the tasks lists in TasksCard - RTLSupport view
  rtlBugs,
  rtlWebsite,
  rtlServer,
  // data for datatables.net in DataTables view
  dataTable,
  memberTable
};
