/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import Files from "react-files";
import moment from "moment";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirestore } from "react-redux-firebase";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// icons
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Assignment from "@material-ui/icons/Assignment";

// Custom Imports
import Rich from "../../../components/TextEntry/Rich";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  formText: {
    fontWeight: "400",
    margin: "0 0 0 0"
  },
  table: {
    minWidth: 200
  },
  agendaInfo: {
    color: "black"
  },
  avatar: {
    width: 60,
    height: 60
  }
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: [],
      topicTitle: "",
      editing: null,
      editingIndex: null,
      editingDesc: "",
      attachments: [],
      alert: null
    };
    this.createTopic = this.createTopic.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  sendState() {
    return this.state;
  }

  isValidated() {
    if (this.state.topics.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  setEditing(row, location) {
    this.setState({
      topicTitle: row.title,
      editingDesc: row.description,
      attachments: row.attachments,
      editing: true,
      editingIndex: location
    });
  }

  basicAlert() {
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Please enter a topic with at least 5 characters"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnStyle={{
            backgroundColor: "red",
            color: "white",
            fontSize: "20px",
            padding: "5px 20px",
            borderRadius: "8px",
            fontWeight: "600"
          }}
        />
      )
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  handleFormChange = event => {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    });
  };

  createTopic() {
    // Check if there is editing
    if (this.state.editing) {
      const { topics, editingIndex } = this.state;
      topics[editingIndex].title = this.state.topicTitle;
      topics[editingIndex].description = this.refs.rich.getDescription();
      topics[editingIndex].attachements = this.state.attachments;
      this.setState({
        editing: null,
        topicTitle: "",
        attachments: [],
        topics
      });
      this.refs.rich.clearEntries();
    } else {
      if (this.state.topicTitle.length > 5) {
        const description = this.refs.rich.getDescription();
        this.setState(state => {
          const topics = state.topics.concat({
            title: this.state.topicTitle,
            description,
            attachments: this.state.attachments
          });

          return {
            topics,
            topicTitle: "",
            attachments: []
          };
        });
        this.refs.rich.clearEntries();
      } else {
        this.basicAlert();
      }
    }
  }

  handleRemove(e) {
    if (e.attachments.length > 0) {
      this.removeAttachment(e.attachments[0].location);
    }
    const newTopics = this.state.topics.filter(
      topic => e.title !== topic.title
    );
    this.setState({
      topics: newTopics
    });
  }

  handleEditTopic(newValue, id, lastValue) {
    let objIndex = this.state.topics.findIndex(obj => obj.id === id);
    this.setState(prevState => {
      const agenda = [...prevState.agenda];
      agenda[objIndex] = { ...agenda[objIndex], title: newValue };
      return { agenda };
    });
  }

  handleRemoveAttachment(e) {
    var ref = this.props.firebase.storage().ref(e.location);
    ref
      .delete()
      .then(function() {
        // File deleted successfully
      })
      .catch(function(error) {
        // Uh-oh, an error occurred!
      });
    const newArray = this.state.attachments.filter(
      item => e.name !== item.name
    );
    this.setState({
      attachments: newArray
    });
  }
  removeAttachment(location) {
    var ref = this.props.firebase.storage().ref(location);
    ref
      .delete()
      .then(function() {
        // File deleted successfully
      })
      .catch(function(error) {
        // Uh-oh, an error occurred!
      });
  }
  handleEditDescription(newValue, id, firstValue) {
    // let objIndex = this.state.data.findIndex(obj => obj.id === id);
    // this.state.data[objIndex] = { id: id, first: firstValue, last: newValue };
    // setData([...data]);
  }

  onFilesChange = files => {
    var that = this;
    const date = new Date();
    const folderDate = moment(date).format("YYYYMMDD");
    // eslint-disable-next-line prettier/prettier
    const location = `voteAttachments/${this.props.allStates.meeting.simpleSelect}/${folderDate}/${files[0].name}`;
    var storageRef = this.props.firebase.storage().ref(location);
    var status = storageRef.put(files[0]);
    status.on(
      "state_changed",
      function progress(snapshot) {
        // console.log(snapshot.bytesTransferred);
      },
      function error(err) {
        console.log("An error has occurred " + err);
      },
      function complete() {
        // get url of file
        status.snapshot.ref.getDownloadURL().then(downloadURL => {
          console.log(downloadURL);
          that.setState(prevState => ({
            attachments: [
              ...prevState.attachments,
              { name: files[0].name, url: downloadURL, location }
            ]
          }));
        });
      }
    );
  };

  onFilesError = (error, files) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.state.alert}
        <GridContainer justify="center" alignItems="center">
          <GridItem xs={6}>
            <h4 className={classes.infoText}>
              Now lets setup the votes for this meeting...
            </h4>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader icon>
                <CardIcon color="info">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.agendaInfo}>Create Vote</h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.handleForm}>
                  <h4 className={classes.formText}>Title:</h4>
                  <CustomInput
                    id="topic-text"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      name: "topicTitle",
                      value: this.state.topicTitle,
                      onChange: this.handleFormChange
                    }}
                    helpText="Enter the basic title of this vote"
                    style={{ paddingTop: "0" }}
                  />
                  <h4
                    className={classes.formText}
                    style={{ paddingBottom: ".5em" }}
                  >
                    Description:
                  </h4>
                  <Rich ref="rich" content={this.state.editingDesc} />
                </form>
                <h4 className={classes.formText} style={{ marginTop: "15px" }}>
                  Attachments:
                </h4>
                <Files
                  className="files-dropzone"
                  onChange={this.onFilesChange}
                  onError={this.onFilesError}
                  accepts={[".pdf", ".pptx", ".ppt"]}
                  multiple
                  maxFiles={3}
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                >
                  <Button color="primary" style={{ marginTop: "10px" }}>
                    Press to select files
                  </Button>
                </Files>
                {this.state.attachments.length > 0 ? (
                  <ul>
                    {this.state.attachments.map(file => (
                      <li>
                        <Delete
                          style={{ marginRight: "10px" }}
                          onClick={() => this.handleRemoveAttachment(file)}
                        />
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {file.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No files attached</p>
                )}
                {this.state.editing === true ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: "30px" }}
                    onClick={this.createTopic}
                  >
                    Update Vote
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: "30px" }}
                    onClick={this.createTopic}
                  >
                    Add Vote
                  </Button>
                )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={6} sm={6}>
            <Card>
              <CardHeader icon>
                <CardIcon color="info">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.agendaInfo}>Votes Entered</h4>
              </CardHeader>
              <CardBody>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell align="left">Description</TableCell>
                      {/* <TableCell alight="center">Created By</TableCell> */}
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.topics.length > 0
                      ? this.state.topics.map((row, index) => (
                          <TableRow key={row.id}>
                            <TableCell align="left">{row.title}</TableCell>
                            <TableCell padding="none" size="small" align="left">
                              {row.description}
                            </TableCell>
                            <TableCell padding="none" align="left">
                              <Edit
                                onClick={() => this.setEditing(row, index)}
                              />
                            </TableCell>
                            <TableCell padding="none" align="left">
                              <Delete onClick={() => this.handleRemove(row)} />
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Step3.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};
const enhance = compose(
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(withStyles(style)(Step3));
