/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import classNames from "classnames";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirestore } from "react-redux-firebase";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Add from "@material-ui/icons/Add";

// core components
// import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      alert: null
    };
    this.inputAlert = this.inputAlert.bind(this);
  }
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  inputAlert() {
    this.setState({
      open: false,
      alert: (
        <SweetAlert
          input
          inputType="text"
          showCancel
          style={{ display: "block"}}
          title="Enter new meeting name..."
          onConfirm={e => this.inputConfirmAlert(e)}
          onCancel={() => this.hideAlert()}
          confirmBtnStyle={{
            backgroundColor: "green",
            color: "white",
            fontSize: "20px",
            padding: "5px 20px",
            borderRadius: "8px",
            fontWeight: "600"
          }}
          cancelBtnStyle={{
            backgroundColor: "red",
            color: "white",
            fontSize: "20px",
            padding: "5px 20px",
            borderRadius: "8px",
            fontWeight: "600"
          }}
        />
      )
    });
  }
  inputConfirmAlert(e) {
    this.props.firestore.add(
      { collection: "meetingTypes" },
      {
        name: e,
        activeMembers: []
      }
    );
    this.setState({ alert: null });
  }
  hideAlert() {
    this.setState({
      alert: null
    });
  }
  render() {
    const { classes, rtlActive } = this.props;
    const { open } = this.state;
    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive }
    );
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });
    return (
      <div className={wrapper}>
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="New"
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClick}
            className={classes.buttonLink}
            muiClasses={{
              label: ""
            }}
            buttonRef={node => {
              this.anchorEl = node;
            }}
          >
            <Add
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
          </Button>
          <Popper
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !open,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true
            })}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      <NavLink
                        to="/admin/profile"
                        className={dropdownItem}
                        onClick={() => this.handleClose()}
                        exact
                      >
                        New Member
                      </NavLink>
                      <MenuItem
                        onClick={this.inputAlert}
                        className={dropdownItem}
                      >
                        New Meeting Type
                      </MenuItem>
                      <NavLink
                        to="/admin/wizard"
                        className={dropdownItem}
                        onClick={() => this.handleClose()}
                        exact
                      >
                        Schedule a Meeting
                      </NavLink>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        {this.state.alert}
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

const enhance = compose(
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(withStyles(adminNavbarLinksStyle)(HeaderLinks));
