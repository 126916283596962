/* eslint-disable no-unused-vars */
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import MembersTable from "../Tables/MembersTable";
import MemberSetup from "../ModalConfigs/MemberSetup";

class MembersDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: true
    };
  }

  handleChange = () => {
    this.setState(prevState => ({
      table: !prevState.table
    }));
  };
  handleMember = data => {
    this.setState(prevState => ({
      member: data,
      table: !prevState.table
    }));
  };

  render() {
    return (
      <React.Fragment>
        {this.state.table ? (
          <MembersTable
            showInfo={this.handleMember}
            members={this.props.members}
          />
        ) : (
          <MemberSetup
            showTable={this.handleChange}
            member={this.state.member}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    members: state.firestore.ordered.members
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onIncrementDummy: () => dispatch(increment()),
    // onStoreResult: val => dispatch(addResult(val)),
    // onDeleteResult: resultId => dispatch(deleteResult(resultId))
  };
};

export default compose(
  firestoreConnect([{ collection: "members" }]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MembersDashboard);
