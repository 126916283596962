/* eslint-disable no-console */
import * as actionTypes from "../actions/actions";

const initialState = {
  results: []
};

const resultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_RESULT: {
      return {
        ...state,
        results: state.results.concat({ id: Date.now(), value: action.result })
      };
    }
    case actionTypes.DELETE_RESULT: {
      const newResults = state.results.filter(
        result => result.id !== action.id
      );
      return {
        ...state,
        results: newResults
      };
    }
    default:
      return state;
  }
};

export default resultsReducer;
