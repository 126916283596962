/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirestore } from "react-redux-firebase";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Step1 from "./Step1.jsx";
import Step2 from "./Step2.jsx";
import Step3 from "./Step3.jsx";

class WizardView extends React.Component {
  createMeeting = data => {
    const timeStamp = this.props.firestore.FieldValue.serverTimestamp();
    const newAttendees = [];
    for (let i = 0; i < data.member.seatedMembers.length; i++) {
      const newSeat = i + 1;
      const updatedEntry = {
        ...data.member.seatedMembers[i],
        seat: newSeat
      };
      newAttendees.push(updatedEntry);
    }
    const newMeeting = {
      pending: true,
      allDay: false,
      createdOn: timeStamp,
      dateScheduled: data.meeting.meetingDate,
      start: data.meeting.meetingDate,
      end: data.meeting.meetingDate,
      meetingId: data.meeting.simpleSelect,
      name: data.meeting.meetingGiven,
      title: data.meeting.meetingGiven,
      meetingType: data.meeting.meetingName,
      attendees: newAttendees,
      topics: data.agenda.topics
    };

    this.props.firestore.add(
      { collection: `meetingInstances` },
      {
        ...newMeeting
      }
    );

    this.props.firestore.update(`seating/${data.meeting.simpleSelect}`, {
      seatedMembers: data.member.seatedMembers
    });

    this.props.history.goBack();
  };

  render() {
    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Wizard
            validate
            steps={[
              { stepName: "Meeting", stepComponent: Step1, stepId: "meeting" },
              { stepName: "Members", stepComponent: Step2, stepId: "member" },
              { stepName: "Agenda", stepComponent: Step3, stepId: "agenda" }
            ]}
            title="Schedule a Meeting"
            subtitle="Lets walk through getting your next meeting ready to go."
            finishButtonClick={e => this.createMeeting(e)}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

const enhance = compose(
  withFirestore,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);
export default enhance(WizardView);
