/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import Datetime from "react-datetime";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { withFirestore, withFirebase } from "react-redux-firebase";
import Select from "react-select";
import Dropzone from "react-dropzone";
import PhotoCrop from "../Components/PhotoCrop";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import avatar from "assets/img/faces/marc.jpg";

class MemberSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meetings: [],
      member: this.props.member,
      updated: false,
      showCrop: false,
      src: null
    };
  }

  componentDidMount() {
    const meetingsArray =
      this.props.meetings &&
      this.props.meetings.map(meeting => {
        return {
          value: meeting.id,
          label: meeting.name
        };
      });
    this.setState(prevState => ({
      meetings: meetingsArray
    }));
  }

  handleMultiple = (action, value) => {
    switch (value.action) {
      case "select-option": {
        this.setState(prevState => ({
          member: {
            ...prevState.member,
            belongsTo: [
              ...this.state.member.belongsTo,
              {
                id: value.option.value,
                value: value.option.value,
                name: value.option.label,
                label: value.option.label
              }
            ]
          },
          updated: true
        }));
        break;
      }

      case "remove-value": {
        let newArray = this.state.member.belongsTo.filter(
          entry => entry.value !== value.removedValue.value
        );
        this.setState(prevState => ({
          member: {
            ...prevState.member,
            belongsTo: newArray
          },
          updated: true
        }));
        break;
      }
      default: {
        console.log("No Matches");
        break;
      }
    }
  };

  handleVote = value => {
    const voteStatus = value.target.checked;
    this.setState(prevState => ({
      member: {
        ...prevState.member,
        canVote: voteStatus
      },
      updated: true
    }));
  };

  handleFormChange = event => {
    const value = event.target.value;
    const name = event.target.name;
    this.setState(prevState => ({
      member: {
        ...prevState.member,
        [name]: value
      },
      updated: true
    }));
  };

  handleStartDate = date => {
    this.setState(prevState => ({
      member: {
        ...prevState.member,
        termStart: date
      },
      tempStart: date.toDate(),
      updated: true
    }));
  };

  handleEndDate = date => {
    this.setState(prevState => ({
      member: {
        ...prevState.member,
        termEnd: date
      },
      tempEnd: date.toDate(),
      updated: true
    }));
  };

  handleImage = files => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(files[0]);
    }
    this.setState(prevState => ({
      showCrop: true,
      name: files[0].name
    }));
  };

  uploadImage = file => {
    var that = this;
    var storageRef = this.props.firebase
      .storage()
      .ref(`memberImages/${file.name}`);
    var status = storageRef.put(file);
    status.on(
      "state_changed",
      function progress(snapshot) {
        // console.log(snapshot.bytesTransferred);
      },
      function error(err) {
        console.log("An error has occurred " + err);
      },
      function complete() {
        // get url of file
        status.snapshot.ref.getDownloadURL().then(downloadURL => {
          that.setState(prevState => ({
            member: {
              ...prevState.member,
              photoUrl: downloadURL
            },
            updated: true
          }));
        });
      }
    );
  };

  updateProfile = () => {
    const startDate = this.state.tempStart
      ? this.state.tempStart
      : this.state.member.termStart;
    const endDate = this.state.tempEnd
      ? this.state.tempEnd
      : this.state.member.termEnd;
    if (this.state.updated === true) {
      const newMember = {
        ...this.state.member,
        termStart: startDate,
        termEnd: endDate
      };
      this.props.firestore.update(`members/${newMember.id}`, { ...newMember });
      this.props.showTable();
    } else {
      this.props.showTable();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <PermIdentity />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Edit Profile</h4>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="First Name"
                        id="firstName"
                        inputProps={{
                          name: "firstName",
                          value: this.state.member.firstName,
                          onChange: this.handleFormChange
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Last Name"
                        id="lastName"
                        inputProps={{
                          name: "lastName",
                          value: this.state.member.lastName,
                          onChange: this.handleFormChange
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Email address"
                        id="email-address"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          name: "email",
                          value: this.state.member.email,
                          type: "email"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Position Title"
                        id="position"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          name: "position",
                          value: this.state.member.position,
                          onChange: this.handleFormChange,
                          type: "text"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControl fullWidth={true}>
                      <p
                        style={{
                          marginTop: "10px"
                        }}
                      >
                        Term Start
                      </p>
                      <Datetime
                        timeFormat={false}
                        value={this.state.member.termStart.toDate()}
                        inputProps={{ placeholder: "choose date" }}
                        onChange={this.handleStartDate}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControl fullWidth={true}>
                      <p
                        style={{
                          marginTop: "10px"
                        }}
                      >
                        Term End
                      </p>
                      <Datetime
                        timeFormat={false}
                        inputProps={{ placeholder: "choose date" }}
                        onChange={this.handleEndDate}
                        value={this.state.member.termEnd.toDate()}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.member.canVote}
                          onChange={this.handleVote}
                          value={this.state.member.canVote}
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            icon: classes.switchIcon,
                            iconChecked: classes.switchIconChecked,
                            bar: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label="Can Vote"
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <FormControl
                      fullWidth={true}
                      className={classes.selectFormControl}
                    >
                      <p>Member Of</p>
                      <Select
                        // defaultValue={meetings}
                        value={this.state.member.belongsTo}
                        isMulti
                        name="colors"
                        options={this.state.meetings}
                        className="classes.select"
                        classNamePrefix="select"
                        onChange={this.handleMultiple}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
          <br />
          <GridItem xs={12} sm={12} md={4}>
            <Dropzone onDrop={this.handleImage} multiple={false}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div {...getRootProps()}>
                  <Card profile>
                    <CardAvatar profile>
                      <img src={this.state.member.photoUrl} alt="..." />
                    </CardAvatar>
                    <CardBody profile>
                      <input {...getInputProps()} />
                      {isDragActive
                        ? "Drop the image here"
                        : "To update the photo simply click here or drag and drop a new photo"}
                    </CardBody>
                  </Card>
                </div>
              )}
            </Dropzone>
            {this.state.showCrop && (
              <PhotoCrop
                src={this.state.src}
                uploadImage={this.uploadImage}
                name={this.state.name}
              />
            )}
          </GridItem>
        </GridContainer>
        {this.state.updated === true ? (
          <Button color="rose" round onClick={this.updateProfile}>
            Update Profile
          </Button>
        ) : (
          <Button color="rose" round onClick={this.updateProfile}>
            Close
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    meetings: state.firestore.ordered.meetingTypes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onIncrementDummy: () => dispatch(increment()),
    // onStoreResult: val => dispatch(addResult(val)),
    // onDeleteResult: resultId => dispatch(deleteResult(resultId))
  };
};
const enhance = compose(
  withFirestore,
  withFirebase,
  firestoreConnect([{ collection: "meetingTypes" }]),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(withStyles(userProfileStyles)(MemberSetup));
