/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import BigCalendar from "react-big-calendar";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import MeetingEdit from "../Forms/MeetingEdit";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

const localizer = BigCalendar.momentLocalizer(moment);

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      alert: null,
      showForm: false,
      editMeeting: null,
      wasUpdated: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.logged.length !== this.props.logged.length) {
      let scheduledEvents = this.props.logged.map(event => {
        const newEvent = {};
        let color = "";
        if (event.pending === true) {
          color = "green";
        } else {
          color = "red";
        }
        newEvent.id = event.id;
        newEvent.topics = event.topics;
        newEvent.start = event.dateScheduled.toDate();
        newEvent.end = event.dateScheduled.toDate();
        newEvent.title = event.name;
        newEvent.name = event.meetingType;
        newEvent.allDay = false;
        newEvent.color = color;
        newEvent.pending = event.pending;
        return newEvent;
      });
      this.setState({
        events: scheduledEvents
      });
    }
  }

  handleShow = () => {
    this.setState(prevState => ({
      showForm: !prevState.showForm
    }));
  };

  handleUpdate = topics => {
    let newArray = this.state.events.slice();
    for (let index = 0; index < this.state.events.length; index++) {
      if (this.state.events[index].id === this.state.editMeeting.id) {
        newArray[index].topics = topics;
        break;
      }
    }
    this.setState(prevState => ({
      events: newArray
    }));
    this.handleShow();
  };

  selectedEvent(event) {
    if (event.pending === true) {
      this.setState(prevState => ({
        editMeeting: event
      }));
      this.handleShow();
    } else {
      this.basicAlert();
    }
  }

  basicAlert() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Meeting is Complete"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnStyle={{
            backgroundColor: "#4fb33f",
            color: "white",
            fontSize: "25px",
            padding: "5px 20px",
            borderRadius: "8px",
            fontWeight: "600"
          }}
        >
          This meeting has been completed. You will be able to see full meeting
          details by clicking this in a future release.
        </SweetAlert>
      )
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }
  eventColors(event, start, end, isSelected) {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  }
  render() {
    return (
      <React.Fragment>
        {this.state.alert}
        {this.state.showForm ? (
          <div>
            <MeetingEdit
              meeting={this.state.editMeeting}
              finished={this.handleShow}
              updated={this.handleUpdate}
            />
          </div>
        ) : (
          <div>
            <Heading textAlign="center" title="DigiVote Meeting Calendar" />
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={10}>
                <Card>
                  <CardBody calendar>
                    {this.state.events && (
                      <BigCalendar
                        selectable
                        localizer={localizer}
                        events={this.state.events}
                        defaultView="month"
                        scrollToTime={new Date(1970, 1, 1, 6)}
                        defaultDate={new Date()}
                        onSelectEvent={event => this.selectedEvent(event)}
                        eventPropGetter={this.eventColors}
                      />
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    logged: state.firestore.ordered.meetingInstances
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default compose(
  firestoreConnect([{ collection: "meetingInstances" }]),
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(buttonStyle)(Calendar));
