/* eslint-disable no-console */
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

import thunk from "redux-thunk";

import counterReducer from "./reducers/counter";
import resultsReducer from "./reducers/result";

// Redux
const logger = store => {
  return next => {
    return action => {
      // console.log("[Middleware] Dispatching", action);
      const result = next(action);
      return result;
    };
  };
};

// Enabled Redux Tools
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  ctr: counterReducer,
  res: resultsReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer
});

const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunk, logger))
);

export default store;
