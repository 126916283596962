/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import Datetime from "react-datetime";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { withFirestore, withFirebase } from "react-redux-firebase";
// @material-ui/icons

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  meetingDate: {
    marginTop: "14px"
  }
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      meetingName: "",
      meetingGiven: "",
      meetingDate: "",
      localDate: ""
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
    for (let i = 0; i < this.props.meetings.length; i++) {
      if (event.target.value === this.props.meetings[i].id)
        this.setState({
          meetingGiven: this.props.meetings[i].name
        });
    }
  };

  handleDate = event => {
    this.setState({
      localDate: event.format("dddd, MMMM Do YYYY"),
      meetingDate: event.toDate()
    });
  };

  isValidated() {
    if (
      this.state.simpleSelect.length === 0 ||
      this.state.meetingDate.length === 0 ||
      this.state.meetingName.length < 3
    ) {
      return false;
    } else {
      return true;
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <h4 className={classes.infoText}>
              First let's choose the meeting and when it will take place.
            </h4>
          </GridItem>
          <GridItem xs={4}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Meeting
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={this.state.simpleSelect}
                onChange={this.handleSimple}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
              >
                {this.props.meetings.map(meeting => {
                  return (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem
                      }}
                      value={meeting.id}
                      key={meeting.id}
                    >
                      {meeting.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={4}>
            <FormControl fullWidth={true} className={classes.meetingDate}>
              <Datetime
                timeFormat={false}
                inputProps={{ placeholder: "Meeting Date" }}
                onChange={this.handleDate}
                className={classes.selectLabel}
              />
            </FormControl>
          </GridItem>
        </GridContainer>
        <br />
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Friendly Meeting Name"
              id="meetingName"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "meetingName",
                value: this.state.meetingName,
                onChange: this.handleSimple,
                type: "text"
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    meetings: state.firestore.ordered.meetingTypes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onIncrementDummy: () => dispatch(increment()),
    // onStoreResult: val => dispatch(addResult(val)),
    // onDeleteResult: resultId => dispatch(deleteResult(resultId))
  };
};
const enhance = compose(
  withFirestore,
  withFirebase,
  firestoreConnect([{ collection: "meetingTypes" }]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(withStyles(style)(Step1));
