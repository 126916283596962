/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { firebaseConnect } from "react-redux-firebase";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// custom sytling
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

const LoginPage = props => {
  const [creds, setCreds] = useState({
    loginEmail: "",
    loginEmailState: "",
    loginPassword: "",
    loginPasswordState: ""
  });
  const [title, setTitle] = useState("Log In");

  const [cardAnimation, setcardAnimation] = useState({
    cardAnimaton: "cardHidden"
  });

  const handleChange = e => {
    setCreds({ ...creds, [e.target.name]: e.target.value });
  };

  const submitHandler = obj => {
    obj.preventDefault();

    const email = creds.loginEmail;
    const password = creds.loginPassword;

    if (email.trim().length === 0 || password.trim().length === 0) {
      return;
    }
    try {
      props.firebase
        .auth()
        .setPersistence(props.firebase.auth.Auth.Persistence.SESSION)
        .then(function() {
          return props.firebase
            .auth()
            .signInWithEmailAndPassword(creds.loginEmail, creds.loginPassword);
        })
        .catch(function(error) {
          console.log(error.code);
          switch (error.code) {
            case "auth/user-not-found":
              console.log("Please check email address");
              setTitle("Please check your email");
              break;
            case "auth/wrong-password":
              console.log("Incorrect Password");
              setTitle("Incorrect Password");
              break;
            case "auth/too-many-requests":
              console.log("Incorrect Password");
              setTitle("Too many tries.  Please wait 2 minutes");
              break;
            default:
              console.log("An unknown Error has occured");
              setTitle("Unknown Error");
              break;
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={props.classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={submitHandler}>
            <Card login className={props.classes[cardAnimation]}>
              <CardHeader
                className={`${props.classes.cardHeader} ${props.classes.textCenter}`}
                color="rose"
              >
                <h4 className={props.classes.cardTitle}>{title}</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  success={creds.loginEmailState === "success"}
                  error={creds.loginEmailState === "error"}
                  labelText="Email Address *"
                  id="loginemail"
                  value={creds.loginEmail}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => handleChange(e),
                    type: "email",
                    name: "loginEmail"
                  }}
                />
                <CustomInput
                  success={creds.loginPasswordState === "success"}
                  error={creds.loginPasswordState === "error"}
                  labelText="Password *"
                  id="loginpassword"
                  value={creds.loginPassword}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => handleChange(e),
                    type: "password",
                    name: "loginPassword"
                  }}
                />
              </CardBody>
              <CardFooter className={props.classes.justifyContentCenter}>
                <Button
                  color="rose"
                  simple
                  size="lg"
                  block
                  type="submit"
                  //onClick={this.loginClick}
                >
                  Let's Go
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
};

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default firebaseConnect()(withStyles(loginPageStyle)(LoginPage));
