/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";

const styles = theme => ({
  card: {
    maxWidth: 400
  },
  avatar: {
    width: 60,
    height: 60
  }
});

class NewCard extends React.Component {
  componentDidMount() {
    //console.log(this.props);
  }
  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar alt="X" src={this.props.photo} className={classes.avatar} />
          }
          title={this.props.displayName}
          subheader={this.props.position}
        />
      </Card>
    );
  }
}

NewCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NewCard);
