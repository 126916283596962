/* eslint-disable no-unused-vars */
import React, { useState } from "react";

//Material UI Core Components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
//import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";

// Material UI Icons
import Check from "@material-ui/icons/Check";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import { withStyles } from "@material-ui/core";

const MeetingOptions = props => {
  const [checked, setChecked] = useState(false);
  const [timerValue, setTimerValue] = useState(null);
  const [voteValue, setVoteValue] = useState(null);

  const handleChange = () => {
    if (checked === true) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  const handleTimerValue = event => {
    setTimerValue(event.target.value);
  };

  const handleVoteValue = event => {
    setVoteValue(event.target.value);
  };

  return (
    <div className={props.classes.block}>
      <FormControl fullWidth className={props.classes.selectFormControl}>
        <InputLabel
          htmlFor="simple-select"
          className={props.classes.selectLabel}
        >
          Default Timer Value
        </InputLabel>
        <Select
          MenuProps={{
            className: props.classes.selectMenu
          }}
          classes={{
            select: props.classes.select
          }}
          value={timerValue}
          onChange={handleTimerValue}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select"
          }}
        >
          <MenuItem
            disabled
            classes={{
              root: props.classes.selectMenuItem
            }}
          >
            Value
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="2"
          >
            2 Minutes
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="3"
          >
            3 Minutes
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="4"
          >
            4 Minutes
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="5"
          >
            5 Minutes
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="6"
          >
            6 Minutes
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="7"
          >
            7 Minutes
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="8"
          >
            8 Minutes
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="9"
          >
            9 Minutes
          </MenuItem>
          <MenuItem
            classes={{
              root: props.classes.selectMenuItem,
              selected: props.classes.selectMenuItemSelected
            }}
            value="10"
          >
            10 Minutes
          </MenuItem>
        </Select>

        <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              onClick={handleChange}
              checkedIcon={<Check className={props.classes.checkedIcon} />}
              icon={<Check className={props.classes.uncheckedIcon} />}
              classes={{
                checked: props.classes.checked,
                root: props.classes.checkRoot
              }}
            />
          }
          classes={{
            label: props.classes.label
          }}
          label="Automatic Mic Shutoff on Timer End"
        />
      </FormControl>
    </div>
  );
};

export default withStyles(extendedFormsStyle)(MeetingOptions);
