/* eslint-disable no-console */
const allen = {
  apiKey: "AIzaSyCAQDOgsTH6bTBlqTQ-x7YbYFy8KwL42fY",
  authDomain: "allenvote-e0efa.firebaseapp.com",
  databaseURL: "https://allenvote-e0efa.firebaseio.com",
  projectId: "allenvote-e0efa",
  storageBucket: "allenvote-e0efa.appspot.com",
  messagingSenderId: "952169168414"
};

const test = {
  apiKey: "AIzaSyCV34D3lF3JViHIDtz9dkZbLjFkk-HarV8",
  authDomain: "test-798e3.firebaseapp.com",
  databaseURL: "https://test-798e3.firebaseio.com",
  projectId: "test-798e3",
  storageBucket: "test-798e3.appspot.com",
  messagingSenderId: "802651636449",
  appId: "1:802651636449:web:3ee95abc72253176"
};

const config = process.env.REACT_APP_CLIENT === "dev" ? test : allen;

export const firebaseConfig = {
  ...config
};

export const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true
};
