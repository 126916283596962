/* eslint-disable no-console */
import * as actionTypes from "../actions/actions";

const initialState = {
  dummy: 0,
  auth: null
};

const counterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INCREMENT: {
      return {
        ...state,
        dummy: state.dummy + 1
      };
    }
    case actionTypes.ADD_RESULT: {
      return {
        ...state,
        dummy: 0
      };
    }
    default:
      return state;
  }
};

export default counterReducer;
